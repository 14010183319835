<script setup>
export default {
  props: {
    drawer: Boolean,
    handleClose: Function,
    disable: Boolean,
    cureNoteDetail: Object,
    plasticsList: Array,
    plasticsObj: Object,
    plasticsAllList: Array
  },
  data() {
    return {
      activeNames: [1],
      upList: [],
      downList: [],
      sheetList: []
    };
  },
  methods: {
    changeActiveNames(type, index) {
      if (!type) {
        this.activeNames = this.upList.map((_, i) => i + 1);
      } else {
        this.activeNames = [index];
      }
    },
    submitData() {
      const { productionContentList } = this.cureNoteDetail?.batchInfo;
      const list = productionContentList.filter(v => v.type === "4");
      const _upList = this.upList.flat(2);
      const _downList = this.downList.flat(2);
      const _contentList = [..._upList, ..._downList, ...list];
      this.$emit("onChange", _contentList);
    },
    countStep(total, batch) {
      const batchArr = batch?.split(",").map(v => Number(v)) || [];
      return batchArr?.map((v, index) => {
        const startBatch = batchArr.slice(index, batchArr.length + 1);
        const endBatch = batchArr.slice(0, index + 1);
        const startCount = total - startBatch.reduce((a, b) => a + b) + 1;
        const endCount = endBatch.reduce((a, b) => a + b);
        return endCount < startCount ? "0" : `${startCount}-${endCount}`;
      });
    },
    tableWidth(item) {
      // 根据批次的项数动态计算表格宽度
      // return 1.4 + item.length * 1 >= 10 ? 10 : 1.4 + item.length * 1;
      return 1.4 + item.length * 1;
    },
    closeOpen() {
      this.$emit("close");
      this.activeNames = [];
    }
  },

  watch: {
    cureNoteDetail: {
      handler(val) {
        const {
          productionContentList,
          maxillarySteps,
          mandibularSteps,
          maxillary,
          mandibular
        } = val?.batchInfo;
        const _ups = this.countStep(maxillary, maxillarySteps);
        const _downs = this.countStep(mandibular, mandibularSteps);
        const upList = [];
        const downList = [];
        _ups.forEach((v, index) => {
          upList?.push([]);
          downList?.push([]);
          const ups = v?.split("-");
          const downs = _downs[index]?.split("-");
          productionContentList.forEach(item => {
            if (item.type !== "4") {
              if (item.position === "1") {
                if (
                  Number(ups[0]) <= item.step &&
                  item.step <= Number(ups[1])
                ) {
                  upList?.[index]?.push(item);
                }
              }
              if (item.position === "2") {
                if (
                  Number(downs[0]) <= item.step &&
                  item.step <= Number(downs[1])
                ) {
                  downList?.[index]?.push(item);
                }
              }
            }
          });
        });
        this.upList = upList;
        this.downList = downList;
      },
      deep: true,
      immediate: true
    },
    plasticsAllList: {
      handler(nval) {
        const { productionContentList } = this.cureNoteDetail?.batchInfo;
        const sheetList = [];
        productionContentList.forEach(item => {
          if (item.type !== "4") {
            sheetList.push(item.plasticSheetId);
          }
        });
        let _list = [...this.plasticsList];
        nval.forEach(item => {
          if (sheetList.includes(item.id)) {
            _list.push(item);
          }
        });
        _list = Array.from(
          new Map(_list.map(item => [item.id, item])).values()
        );
        this.sheetList = [...new Set(_list)].sort(
          (a, b) => b.orderNo - a.orderNo
        );
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<template>
  <el-drawer
    :visible.sync="drawer"
    custom-class="drawer_content"
    size="70%"
    @close="closeOpen"
  >
    <template #title>
      <h2 v-if="!disable" class="drawer_header_title">
        修改批次
      </h2>
      <h2 v-if="disable" class="drawer_header_title">
        批次信息
      </h2>
    </template>
    <div v-if="drawer" class="drawer_content_body">
      <div class="drawer_body">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in upList"
            :name="index + 1"
            :title="`第${index + 1}批次`"
          >
            <div class="table_content">
              <table :style="{ width: tableWidth(item) + 'rem' }">
                <thead>
                  <tr>
                    <th class="w40">批次</th>
                    <th class="w40">上颌</th>
                    <th class="w60">步数</th>
                    <th
                      v-for="(it, idx) in item"
                      :key="idx"
                      style="width: 1rem;"
                    >
                      {{ it.step }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="w40">{{ index + 1 }}</td>
                    <td class="w40"></td>
                    <td class="w60">牙套材质</td>
                    <td v-for="(it, index) in item" :key="index" class="w60">
                      <el-select
                        v-if="!disable"
                        v-model="it.plasticSheetId"
                        style="width: 0.9rem;height: 20px"
                      >
                        <el-option
                          v-for="m in sheetList"
                          :key="m.id"
                          :label="m.name"
                          :value="m.id"
                        />
                      </el-select>
                      <span v-else>{{ plasticsObj[it.plasticSheetId] }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table_content">
              <table :style="{ width: tableWidth(downList[index]) + 'rem' }">
                <thead>
                  <tr>
                    <th class="w40">批次</th>
                    <th class="w40">下颌</th>
                    <th class="w60">步数</th>
                    <th
                      v-for="(it, idx) in downList[index]"
                      :key="idx"
                      style="width: 1rem;"
                    >
                      {{ it.step }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="w40">{{ index + 1 }}</td>
                    <td class="w40"></td>
                    <td class="w60">牙套材质</td>
                    <td
                      v-for="(it, index) in downList[index]"
                      :key="index"
                      class="w60"
                    >
                      <el-select
                        v-if="!disable"
                        v-model="it.plasticSheetId"
                        style="width: 0.9rem;height: 20px"
                      >
                        <el-option
                          v-for="m in sheetList"
                          :key="m.id"
                          :label="m.name"
                          :value="m.id"
                        />
                      </el-select>
                      <span v-else>{{ plasticsObj[it.plasticSheetId] }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="drawer_footer">
        <el-button class="drawer_confirm" type="primary" @click="submitData"
          >确认
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<style lang="scss" scoped>
::v-deep .el-drawer {
  background-color: var(--backgroundColor) !important;
  color: var(--textColor) !important;
}

.drawer_header_title {
  color: var(--textColor) !important;
}

::v-deep .el-drawer__header {
  margin-bottom: 12px;
  padding: 10px 20px 0;
}

.drawer_content_body {
  height: 100%;
}

.drawer_body {
  padding: 0 20px;
  width: auto;
  height: 90%;
  overflow-y: scroll;
}

table {
  //width: 100%;
  border-collapse: collapse;
  overflow: scroll !important;
  width: 1000px;
  //user-select: none;
  table-layout: fixed !important;
  margin-bottom: 5px;
  background-color: var(--backgroundColor) !important;
  color: var(--textColor) !important;
}

th,
td {
  border: 1px solid var(--darkBatchBorderColor);
  padding: 4px;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--darkBatchThTdColor) !important;
  color: var(--textColor) !important;
}

th {
  background-color: var(--darkBatchThColor);
}

.w40 {
  width: 40px !important;
}

.w60 {
  width: 65px !important;
}

::v-deep .el-select {
  height: 35px !important;

  .el-input__suffix {
    right: 0 !important;
  }

  //.el-select-dropdown {
  //  background-color: #bbbbbb !important;
  //
  //  .el-scrollbar {
  //    background-color: #bbbbbb !important;
  //  }
  //}

  .el-input__inner {
    height: 35px !important;
    padding: 4px !important;
    padding-right: 20px !important;
    background-color: var(--darkBatchInputColor) !important;
    color: var(--textColor) !important;
  }
}

::v-deep .el-collapse {
  background-color: var(--backgroundColor) !important;
  color: var(--textColor) !important;
  border-bottom: 0.01rem solid var(--darkBatchCollapseColor);
}

::v-deep .el-collapse-item {
  background-color: var(--backgroundColor) !important;
  color: var(--textColor) !important;

  .el-collapse-item__content {
    padding-bottom: 5px !important;
    background-color: var(--backgroundColor) !important;
    color: var(--textColor) !important;
  }

  .el-collapse-item__header {
    background-color: var(--backgroundColor) !important;
    border-bottom: 0.01rem solid var(--darkBatchBorderColor) !important;
    color: var(--textColor) !important;
    margin-bottom: 5px;
  }

  .el-collapse-item__wrap {
    border-color: var(--darkBatchBorderColor) !important;
  }
}

.table_content {
  width: 100%;
  overflow: auto;
  margin-bottom: 10px;
  background-color: var(--backgroundColor) !important;
  color: var(--textColor) !important;
}

.drawer_footer {
  width: 100%;
  //bottom: 0;
  height: 60px;
  //right: 0;
  position: relative;
  margin-top: 10px;

  .drawer_confirm {
    position: absolute;
    right: 20px;
    width: 200px;
    color: #333;
  }
}
</style>
