<template>
  <div class="container" :style="themeVars">
    <div class="uploading" v-if="uploading">
      <div class="uploading-content">
        <img
          src="https://picture.aismile.cn/web-static/aismile.cn/file-uploading.gif"
          alt=""
        />
        <span>Loading...</span>
      </div>
    </div>
    <transition name="fade">
      <div v-if="isLoading" class="loading-mask">
        <i class="el-icon-loading" style="color: #fcc80e"></i>
      </div>
    </transition>
    <div v-if="!isLoading" class="content">
      <OrthoBuilder
        ref="ortho"
        :cureNoteDetail="cureNoteDetail"
        :caseDetail="caseDetail"
        :userDetail="userDetail"
        :environmene="environmene"
        :plasticsList="plasticsList"
        :plasticSheetListAll="plasticSheetListAll"
        :plasticSheetObj="plasticSheetObj"
        :plasticsObj="plasticsObj"
        :toothViewData="toothViewData"
        @toothLog="toothLog"
        @canEdit="canEdit"
        @canCompare="canCompare"
        @uploadingSuccess="uploadingSuccess"
        @editChange="editChange"
        :modData="modData"
        :comparePlanList="comparePlanList"
        @onThemeChange="onThemeChange"
        :themeDetail="themeDetail"
        @shareLoadingOnChange="shareLoadingOnChange"
        @sharePercentOnChange="sharePercentOnChange"
      />
      <Interaction
        :cureNoteDetail="cureNoteDetail"
        :isEdit="isEdit"
        :userDetail="userDetail"
        :caseDetail="caseDetail"
        :environmene="environmene"
        :canEdit="isAnimationCanEdit"
        :canCompare="animationCanCompare"
        :plasticsList="plasticsList"
        :plasticSheetObj="plasticSheetObj"
        :plasticsObj="plasticsObj"
        :diagnosisDetail="diagnosisDetail"
        @stagingData="stagingData"
        @changeViewType="changeViewType"
        @historyCompare="historyCompare"
        @updateComparePlanList="updateComparePlanList"
        :mod3DRecord="mod3DRecord"
        :themeVars="themeVars"
      />
    </div>
    <!--    <init-notice-->
    <!--      v-if="showViewInitNotice"-->
    <!--      :hasEditViewControl="hasEditViewControl"-->
    <!--      :noticeKey="noticeKey"-->
    <!--      @closeDrawer="showViewInitNotice = false"-->
    <!--    />-->
    <notifyMsgCm
      :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
      ref="notifyMsgCm"
    />
    <div
      v-if="shareLoading"
      style="
        position: fixed;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        font-size: 18px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.92);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
      "
    >
      <div>正在导出，请勿离开页面、缩放窗口、关闭页面，以免造成导出错误。</div>
      <div>{{ sharePercent }}%</div>
    </div>
  </div>
</template>

<script>
import {
  PublicQueryEnvironment,
  caseCureNoteDetail,
  casesDetail,
  caseDiagnosisDetail,
  PublicPlasticSheetList,
  getUserDetailByDetail,
  PublicCurePlanAppRoveConfirmPlan,
  PublicCurePlanApprovePlan,
  confirmCurePlan,
  threeDesignPlanModDetail,
  cureSuggestList,
  threeDThemeDetail,
  modifyThreeDThemeSettingBackgroundColor,
  modifyThreeDThemeSettingBackgroundPic,
  modifyThreeDThemeSettingType,
} from 'common/api/cases';
import initNotice from './components/init-notice.vue';
import { lightTheme, darkTheme } from '@/common/theme/index';
import notifyMsgCm from './notify/notifyMsg';
import OrthoBuilder from './components/orthobuilder';
import Interaction from './components/interaction.vue';
import { each, extend, get } from 'lodash';
import { shouldShowNotice } from '@/common/js/util.js';

export default {
  data() {
    return {
      caseId: '',
      environmene: '',
      cureNoteDetail: {},
      caseDetail: {},
      diagnosisDetail: {},
      plasticsList: [],
      plasticSheetObj: {},
      plasticsObj: {},
      isLoading: true,
      uploading: false,
      toothViewData: {},
      userDetail: {},
      mod3DRecord: '',
      gapAction: {
        create: this.$t('threeD.xz'),
        modify: this.$t('threeD.xgjx'),
        delete: this.$t('threeD.qc'),
      },
      attAction: {
        create: this.$t('threeD.xz'),
        modify: this.$t('threeD.xgjx'),
        delete: this.$t('threeD.sc'),
      },
      gapType: {
        gap: this.$t('threeD.jx'),
        glaze: this.$t('threeD.lyqy'),
      },
      attType: {
        512: this.$t('threeD.fjjx'),
        518: this.$t('threeD.ty'),
        525: this.$t('threeD.tx'),
        522: this.$t('threeD.byh'),
        523: this.$t('threeD.bys'),
        524: this.$t('threeD.hd'),
        526: this.$t('threeD.ylj'),
        527: this.$t('threeD.yhdb'),
        528: this.$t('threeD.ylk'),
      },
      dataChanged: false,
      modifyData: {},
      historyModification: '',
      isAnimationCanEdit: false,
      isEdit: false,
      modData: {},
      comparePlanList: [],
      kind: '',
      // noticeKey: { type: 'view3d_init_notice', productId: '20231106' },
      noticeKey: { type: 'view3d_init_notice', productId: '20241206' },
      showViewInitNotice: false,
      hasEditViewControl: false,
      themeDetail: {
        backgroundColor: '',
        pictureList: [],
        themeType: 'A',
      },
      animationCanCompare: false,
      shareLoading: false,
      sharePercent: 0,
    };
  },
  components: {
    OrthoBuilder,
    Interaction,
    notifyMsgCm,
    initNotice,
  },
  computed: {
    themeVars() {
      const { toothViewVersion } = this.cureNoteDetail;
      const { themeType } = this.themeDetail;
      if (toothViewVersion === '0') {
        return lightTheme;
      } else if (toothViewVersion === '2') {
        return themeType === 'A' ? darkTheme : lightTheme;
      } else {
        return darkTheme;
      }
    },
    orthoRefs() {
      return this.$refs.ortho;
    },
    nowPort() {
      return this.kind;
    },
    amendmentShow() {
      const status = this.caseDetail['curePlanInfo'].status;
      const irdtCanShow = [
        'to_approve_plan',
        'to_reconfirm_plan',
        'to_confirm_plan',
      ];
      const canShow = ['to_doctor_confirm_plan'];
      if (
        this.nowPort === 'RDT' &&
        irdtCanShow.includes(status) &&
        [0, 2].includes(+this.cureNoteDetail['passFlag']) &&
        this.cureNoteDetail['generateFlag'] == '1' &&
        this.cureNoteDetail['historyFlag'] == '0'
      ) {
        return true;
      }
      if (
        this.nowPort === undefined ||
        ['SALES', 'LIANSUO_CLINIC'].includes(this.nowPort)
      ) {
        if (canShow.includes(status)) {
          return true;
        }
      }
    },
  },
  watch: {
    mod3DRecord(newval) {
      if (Object.keys(this.cureNoteDetail).length) {
        const currentKey =
          this.nowPort === 'RDT'
            ? this.cureNoteDetail['rdtMod3DRecord']
            : this.cureNoteDetail['doctorMod3DRecord'];
        if (this.nowPort === 'RDT') {
          if (
            newval !== currentKey &&
            !!this.mod3DRecord &&
            this.amendmentShow
          ) {
            this.dataChanged = true;
          } else {
            this.dataChanged = false;
          }
        } else if (this.nowPort === 'DOCTOR') {
          if (+this.cureNoteDetail['passFlag'] === 0) {
            if (newval !== currentKey && !!this.mod3DRecord) {
              this.dataChanged = true;
            } else {
              this.dataChanged = false;
            }
          }
        }
      }
    },
  },
  methods: {
    get,
    updateComparePlanList(comparePlanList) {
      this.$refs.ortho.send({
        type: 'comparePlanList',
        data: comparePlanList,
      });
    },
    historyCompare(id) {
      this.$refs.ortho.toComparePlan(id);
    },
    canCompare(e) {
      this.animationCanCompare = e;
    },
    canEdit(e) {
      this.isAnimationCanEdit = e;
    },
    editChange(data) {
      this.isEdit = data;
    },
    changeViewType(data) {
      this.isEdit = data;
      this.$refs.ortho.send({
        type: 'setEditing',
        data: data,
      });
    },
    onThemeChange(e) {
      const { themeType, backgroundColor, pictureList } = this.themeDetail;
      const {
        backgroundColor: _backgroundColor,
        pictureList: _pictureList,
        themeType: _themeType,
      } = e;
      const hasDefaultChoose = _pictureList.some(
        (item) => item.chooseFlag === '1'
      );
      const noChoosePicList = _pictureList.map((item) => {
        return { ...item, chooseFlag: '0' };
      });
      if (backgroundColor !== _backgroundColor) {
        if (_backgroundColor !== '') {
          this.themeChangeAction('pictureList', noChoosePicList);
          this.themeChangeAction('backgroundColor', _backgroundColor);
        } else {
          this.themeChangeAction('pictureList', _pictureList);
          this.themeChangeAction('backgroundColor', '');
        }
      } else {
        if (_themeType !== themeType) {
          this.themeChangeAction('backgroundColor', '');
          this.themeChangeAction('pictureList', noChoosePicList);
          this.themeChangeAction('themeType', _themeType);
        } else {
          if (hasDefaultChoose) {
            this.themeChangeAction('pictureList', _pictureList);
            this.themeChangeAction('backgroundColor', '');
          }
        }
      }
      this.themeDetail = e;
    },
    themeChangeAction(type, data) {
      let { token } = this.$route.query;
      let params = {
        userId: this.userDetail['id'],
      };
      if (token) params.token = token;
      switch (type) {
        case 'backgroundColor':
          modifyThreeDThemeSettingBackgroundColor({
            ...params,
            backgroundColor: data,
          });
          break;
        case 'pictureList':
          modifyThreeDThemeSettingBackgroundPic({
            ...params,
            pictureList: data,
          });
          break;
        case 'themeType':
          modifyThreeDThemeSettingType({ ...params, themeType: data });
          break;
      }
    },
    uploadingSuccess(e) {
      this.operatorLogDoctor(e);
    },
    stagingData(data, isAutoSave = false) {
      this.modifyData = data;
      this.isAutoSave = isAutoSave;
      const { type } = data;
      if (this.dataChanged) {
        this.$refs.ortho.save(type, isAutoSave);
      } else {
        this.operatorLogDoctor(this.historyModification);
      }
    },
    operatorLogDoctor(hash) {
      const { curePlanId, ts } = this.$route.query;
      const { threeDimensionalDesignPlanId } = this.cureNoteDetail;
      if (curePlanId) {
        this.uploading = !this.isAutoSave;
        let confirmApi = '';
        if (ts) {
          confirmApi =
            +this.$route.query.stage === 1
              ? PublicCurePlanApprovePlan
              : PublicCurePlanAppRoveConfirmPlan;
        } else {
          const status = this.caseDetail['curePlanInfo'].status;
          if (status == 'to_doctor_confirm_plan') {
            confirmApi = confirmCurePlan;
          } else {
            return;
          }
        }
        const obj = {
          ...this.modifyData,
          id: curePlanId,
          threeDimensionalDesignPlanId,
        };
        delete obj.type;
        if (this.mod3DRecord !== '') {
          obj.mod3DRecord = this.mod3DRecord;
          obj.mod3DFile = hash;
        } else {
          obj.mod3DRecord = '';
        }
        confirmApi(obj)
          .then(() => {
            this.uploading = false;
            if (this.isAutoSave && this.modifyData['type'] === '0') {
              this.dataChanged = false;
              return;
            }
            this.$refs['notifyMsgCm']
              .open({
                text: this.$t('casesDetail.casesChildren.amendments.czcg'),
                type: 'success',
              })
              .then(() => {
                const { userInfo } = this.cureNoteDetail;
                localStorage.setItem(
                  'windowName',
                  userInfo.realName + this.$t('common.common.blxq')
                );
                if (['LIANSUO_CLINIC', 'SALES'].includes(this.nowPort)) {
                  this.amendOpinion = false;
                  return this.init();
                } else {
                  if (this.modifyData['type'] === '0') {
                    this.dataChanged = false;
                    return this.init();
                  } else {
                    this.goPage('/cases/detail');
                  }
                }
              });
          })
          .catch(() => {
            this.uploading = false;
          });
      } else {
        return this.$refs['notifyMsgCm'].open({
          text: this.$t('casesDetail.casesChildren.amendments.czsb'),
          type: 'error',
        });
      }
    },
    goPage(url) {
      let query = this.$route.query;
      sessionStorage.removeItem('hideHead');
      return (
        url &&
        this.$router.push({
          path: url,
          query: extend(
            {},
            { caseId: query.caseId, curePlanId: query.curePlanId }
          ),
        })
      );
    },
    toothLog(data) {
      const { gapLog = [], toothLog = [], attLog = [] } = data;
      const gapTemString = gapLog
        .map((item) => {
          const minFdi = +item.left > +item.right ? item.right : item.left;
          const maxFdi = +item.left > +item.right ? item.left : item.right;
          return `${this.$t('threeD.yw')} ${minFdi}, ${this.$t(
            'threeD.yw'
          )} ${maxFdi} - ${this.gapAction[item.action]} ${
            this.gapType[item.type]
          } ${item.valueAbs ? item.valueAbs : ''}`;
        })
        .join('\n');
      const attTemString = attLog
        .map((item) => {
          const { action, fdi, type, beforeSize, afterSize } = item;
          if (action !== 'modify') {
            return `${this.$t('threeD.yw')} ${fdi} - ${
              this.attAction[action]
            } ${this.attType[type]}${
              [524, 526, 527, 528].includes(type) ? '' : this.$t('threeD.fj')
            }`;
          } else {
            return `${this.$t('threeD.yw')} ${fdi} - ${
              this.attAction[action]
            } ${this.attType[type]}${
              [524, 526, 527, 528].includes(type) ? '' : this.$t('threeD.fj')
            } ${this.$t('threeD.xh')} ${beforeSize} ${this.$t(
              'threeD.to'
            )} ${afterSize} `;
          }
        })
        .join('\n');
      const upJaw = toothLog.filter((item) => item.upper);
      const lowerJaw = toothLog.filter((item) => !item.upper);
      const upJawTemString = upJaw.length
        ? `${this.$t('threeD.shyctz')}：` + upJaw.map((item) => item.fdi)
        : '';
      const lowerJawTemString = lowerJaw.length
        ? `${this.$t('threeD.xhyctz')}：` + lowerJaw.map((item) => item.fdi)
        : '';
      const mod3DRecord = [
        upJawTemString,
        lowerJawTemString,
        gapTemString,
        attTemString,
      ]
        .filter((item) => item.length)
        .join('\n');
      this.mod3DRecord = mod3DRecord;
    },
    handleBeforeUnload(e) {
      if (this.dataChanged) {
        event.preventDefault();
        event.returnValue = '';
        return '确定要离开吗？';
      }
    },
    async init() {
      let { token, cureNoteId, caseId } = this.$route.query;
      const nowEnvironmene = await PublicQueryEnvironment({
        key: 'environment',
      });
      this.environmene = nowEnvironmene[0].value || 'dev';
      const userDetail = token
        ? await getUserDetailByDetail({ token })
        : await getUserDetailByDetail();
      const themeDetail = token
        ? await threeDThemeDetail({ token })
        : await threeDThemeDetail();
      this.themeDetail = Object.assign(this.themeDetail, themeDetail);
      this.kind = userDetail['kind'];
      let documentTitle = {
        DOCTOR: '',
        CLINIC: this.$t('threeD.zsd'),
        RDT: 'iRDT',
        SYS: this.$t('threeD.csd'),
        AGENT: this.$t('threeD.jxs'),
        SALES: this.$t('threeD.xsd'),
        LIANSUO_CLINIC: '连锁诊所端',
      }[this.kind];
      if (this.kind === 'DOCTOR') {
        document.title = 'OrthoBuilder';
      } else {
        document.title = `OrthoBuilder-${documentTitle}`;
      }
      this.hasEditViewControl = userDetail['mod3DFlag'] === '1';
      const cureNoteDetail = await caseCureNoteDetail(
        token ? { id: cureNoteId, token } : cureNoteId
      );
      const {
        toothViewVersion,
        toothView,
        upperJaw,
        lowerJaw,
        id,
        threeDimensionalDesignPlanId,
      } = cureNoteDetail;
      const modRecordFlag =
        this.kind === 'RDT' ? 'rdtMod3DRecord' : 'doctorMod3DRecord';
      const hasModRecord = modRecordFlag in cureNoteDetail;
      if (toothViewVersion === '2') {
        if (hasModRecord) {
          this.mod3DRecord = cureNoteDetail[modRecordFlag];
          if (['RDT', 'DOCTOR'].includes(this.kind)) {
            const modData = await threeDesignPlanModDetail(
              token
                ? {
                    cureNoteId: id,
                    threeDimensionalDesignPlanId,
                    token,
                  }
                : {
                    cureNoteId: id,
                    threeDimensionalDesignPlanId,
                  }
            );
            this.modData = modData;
            const { etag, fileName, key, mime } = modData;
            this.historyModification = {
              etag,
              fileName,
              key,
              mime,
            };
          }
        }
        this.toothViewData = {
          upperJaw: upperJaw,
          lowerJaw: lowerJaw,
          animation: toothView,
        };
      }
      const details = await casesDetail(token ? { id: caseId, token } : caseId);
      const diagnosisDetail = await caseDiagnosisDetail(
        token
          ? { id: cureNoteDetail.diagnosisId, token }
          : cureNoteDetail.diagnosisId
      );
      this.plasticSheetListAll = await PublicPlasticSheetList({ token }); // 用于3d动画回显材质
      const plasticProductId =
        details['bindProductId'] !== '0'
          ? details['bindProductId']
          : details['productId'];
      const plasticsList = await PublicPlasticSheetList(
        token
          ? { productId: plasticProductId, stage: 'B', token }
          : { productId: plasticProductId, stage: 'B' }
      );
      const plasticSheetObj = {};
      const plasticsObj = {};
      each(plasticsList, (it) => {
        plasticSheetObj[it.sheetCode] = it.name;
        plasticsObj[it.id] = it.name;
      });
      this.userDetail = userDetail;
      this.plasticSheetObj = plasticSheetObj;
      this.plasticsObj = plasticsObj;
      this.plasticsList = plasticsList;
      this.cureNoteDetail = cureNoteDetail;
      this.caseDetail = details;
      this.diagnosisDetail = diagnosisDetail;
      this.isLoading = false;
      this.showViewInitNotice = shouldShowNotice(
        this.noticeKey,
        localStorage.getItem('userId')
      );
    },
    async getHistoryList() {
      let { caseId, token, cureNoteId } = this.$route.query;
      const historyList = await cureSuggestList(
        token ? { caseId, token } : { caseId }
      );
      const nowPlan = historyList.find((item) => item.id === cureNoteId);
      let comparePlanList = [];
      if (historyList.length !== 1 && nowPlan.toothViewVersion === '2') {
        each(historyList, (item) => {
          const {
            id,
            toothViewVersion,
            upperJaw,
            lowerJaw,
            toothView,
            number,
            noteName,
          } = item;
          if (id !== cureNoteId && toothViewVersion === '2') {
            comparePlanList.push({
              id,
              title: noteName,
              upperJaw,
              lowerJaw,
              animation: toothView,
            });
          }
          this.comparePlanList = comparePlanList;
        });
      }
    },
    shareLoadingOnChange(val) {
      this.shareLoading = val;
    },
    sharePercentOnChange(val) {
      this.sharePercent = val;
    },
  },
  created() {
    let query = this.$route.query;
    this.caseId = query.caseId;
    this.getHistoryList();
    document.body.style.overflow = 'hidden';
    sessionStorage.setItem('hideHead', '1');
    this.init();
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    document.body.style.overflow = 'visible';
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
</script>

<style lang="scss" scoped>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.uploading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 99999;

  &-content {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translateX(-50%);
    width: 164px;
    height: 64px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #333333;

    img {
      width: 56px;
      height: 56px;
      margin-right: 6px;
    }
  }
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: var(--backgroundColor);

  .content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 32px;
  background-color: #454647;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
