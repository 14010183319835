<template>
  <div class="design-history">
    <div class="title">
      <div class="label">
        <svg class="label-icon" aria-hidden="true">
          <use xlink:href="#icon-lishi_xuanzhong"></use>
        </svg>
        <div class="content-text">
          {{ $t("threeD.lsfa") }}
          <div
            class="history-tips"
            @mouseenter="showTipsContent = true"
            @mouseleave="showTipsContent = false"
          >
            <transition name="fade">
              <div v-if="showTipsContent" class="history-tips-content">
                <div class="arrow"></div>
                <p>{{ `${$t("threeD.czdbandfa")}` }}</p>
                <p>{{ `${$t("threeD.dbzqfaja")}` }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <i class="el-icon-close close-icon" @click="closeDrawer"></i>
    </div>
    <div class="content">
      <div class="loading-mask" v-if="isLoading">
        <img
          src="https://picture.aismile.cn/web-static/aismile.cn/file-uploading.gif"
          alt=""
        />
      </div>
      <div v-if="historyList.length" class="history-content">
        <ul ref="his_Ul" class="his_ul" v-if="historyList.length">
          <li
            class="his_single"
            :class="'his_single' + item.id"
            v-for="(item, index) in historyList"
            :key="item.id"
          >
            <div class="his_right">
              <div class="flex-x-between">
                <h5 class="his_r_h5">
                  <span class="his_h_icon">{{
                    historyList.length - index
                  }}</span>
                  <span class="note_name">{{ item.noteName }}</span>
                </h5>
                <div
                  v-if="+item.id !== 0"
                  :class="
                    item.id === $route.query.cureNoteId ? 'curp-active' : 'curp'
                  "
                >
                  <span @click="openNewThreeD(item)">
                    {{
                      +item.id === +$route.query.cureNoteId
                        ? $t("casesDetail.casesChildren.textPlanChildren.dqfa")
                        : $t("cases.createImage.ck")
                    }}
                  </span>
                  <span
                    v-if="
                      item.id !== $route.query.cureNoteId &&
                        canCompare &&
                        !isEdit && item.toothViewVersion === '2'
                    "
                    class="compare"
                    @click="compare(item.id)"
                    >{{ `${$t("threeD.db")}` }}</span
                  >
                </div>
              </div>
              <p class="create_time">
                {{ item.generateDatetime }}
              </p>

              <div class="his_r_box">
                <div
                  v-if="
                    item.specialInstructions ||
                      item.note ||
                      item.cureSuggestList
                  "
                  class="his_r_box_bc"
                >
                  <div v-if="item.specialInstructions">
                    <p class="doctor_opinion">
                      {{
                        $t("casesDetail.casesChildren.operationChildren.ysjy")
                      }}：
                    </p>
                    <p class="special_explain wsp">
                      {{ item.specialInstructions }}
                    </p>
                  </div>
                  <div
                    :class="{
                      mt10: item.specialInstructions,
                    }"
                    class="mb16"
                    v-if="item.note"
                  >
                    <p class="suggest">
                      {{
                        $t(
                          "casesDetail.casesChildren.operationChildren.ylkjjy"
                        )
                      }}:
                    </p>
                    <p class="honorific_doctor wsp" v-html="item.note">
<!--                      {{ item.note }}-->
                    </p>
                  </div>
                  <div
                    v-if="item.cureSuggestList && item.cureSuggestList.length"
                  >
                    <div
                      v-for="(it, idx) in item.cureSuggestList"
                      :key="idx"
                      class="his_r__yj"
                    >
                      <div class="yj_left">
                        <div
                          :class="{
                            mb16: idx !== item.cureSuggestList.length - 1,
                          }"
                          class="wrap_suggest"
                        >
                          <div class="yj_left_p mb4 flex-x-between">
                            <div
                              :title="cureSuggestNodeObj[it.operatorNode]"
                              class="operator_node fz14 txt-ellipsis"
                            >
                              {{
                                cureSuggestNodeObj[it.operatorNode]
                                  ? cureSuggestNodeObj[it.operatorNode]
                                  : it.suggest
                              }}
                            </div>
                            <div
                              :class="{
                                ml10: !!cureSuggestNodeObj[it.operatorNode],
                              }"
                              class="fz12 operator_create_date_time"
                            >
                              {{ it.createDatetime }}
                            </div>
                          </div>
                          <p
                            v-if="cureSuggestNodeObj[it.operatorNode]"
                            class="yj_left_suggest"
                          >
                            {{ it.suggest }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="no_his" v-else>
        <div class="no_his_pic yellow_pic"></div>
        <p class="no-data">
          {{ $t("casesDetail.casesChildren.operationChildren.zsmylsfa") }}～
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictList } from "common/api/public";
import { cureSuggestList } from "common/api/cases";
import { each } from "lodash";
export default {
  props: ["canEdit", "isEdit", "canCompare"],
  data() {
    return {
      cureSuggestNodeObj: {},
      historyList: [],
      isLoading: true,
      showTipsContent: false,
    };
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    },
    compare(id) {
      this.$emit("historyCompare", id);
    },
    openNewThreeD(item) {
      if (item.id === this.$route.query.cureNoteId) return;
      let obj = this.$route.query;
      let str = "";
      for (let i in obj) {
        str += `${i}=${i === "cureNoteId" ? item.id : obj[i]}&`;
      }
      if (this.cureType === "F") {
        window.open("/exocad?" + str, "_blank");
      } else {
        window.open("/cases/view3d?" + str, "_blank");
      }
    },
    async init() {
      let { caseId, token, cureNoteId } = this.$route.query;
      let dictObj = {};
      const dict = await getDictList("cure_suggest.node");
      each(dict, (item) => (dictObj[item.key] = item.value));
      const historyList = await cureSuggestList(
        token ? { caseId, token } : { caseId }
      );
      const nowPlan = historyList.find((item) => item.id === cureNoteId);
      let comparePlanList = [];
      if (historyList.length !== 1 && nowPlan.toothViewVersion === "2") {
        each(historyList, (item) => {
          const {
            id,
            toothViewVersion,
            upperJaw,
            lowerJaw,
            toothView,
            number,
            noteName,
          } = item;
          if (id !== cureNoteId && toothViewVersion === "2") {
            comparePlanList.push({
              id,
              title: noteName,
              upperJaw,
              lowerJaw,
              animation: toothView,
            });
          }
          this.comparePlanList = comparePlanList;
        });
      }
      this.$emit("updateComparePlanList", comparePlanList);
      this.cureSuggestNodeObj = dictObj;
      this.historyList = historyList;
      this.isLoading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.design-history {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px 0 0;
  color: var(--textColor);
  display: flex;
  flex-direction: column;

  .title {
    padding: 0 30px 0;
    margin-bottom: 10px;
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-text {
      display: flex;
      justify-content: center;
      align-items: center;

      .content {
        font-size: 12px;
        line-height: 16px;
        color: var(--textColor);
      }
    }

    .history-tips {
      position: relative;
      width: 16px;
      height: 16px;
      margin-left: 8px;
      background: var(--help-icon) no-repeat center;
      background-size: 100% 100%;
    }

    .history-tips-content {
      &::before {
        box-sizing: content-box;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 7px;
        left: -13px;
        padding: 0;
        border-right: 7px solid var(--backgroundColor);
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid transparent;
        display: block;
        content: "";
        z-index: 12;
      }

      &::after {
        box-sizing: content-box;
        width: 0px;
        height: 0px;
        position: absolute;
        top: 6px;
        left: -16px;
        padding: 0;
        border-right: 8px solid #828282;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
        display: block;
        content: "";
        z-index: 10;
      }

      background-color: var(--backgroundColor);
      width: 260px;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      padding: 10px;
      font-size: 12px;
      line-height: 16px;
      color: var(--textColor);
      position: absolute;
      right: -12px;
      top: -6px;
      transform: translateX(100%);
      border-radius: 4px;
      border: 1px solid #828282;
      z-index: 10;
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fcc80e;

      &-icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
    }

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .content {
    flex: 1;
    padding: 10px 30px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    color: var(--textColor);
    position: relative;

    .loading-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: var(--backgroundColor);
      justify-content: center;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
      }
    }

    .his_ul {
      .his_single {
        margin-bottom: 26px;

        .his_right {
          width: 100%;

          .his_r_h5 {
            display: flex;
            align-items: center;
            position: relative;

            .his_h_icon {
              display: inline-block;
              width: 0.24rem;
              height: 0.24rem;
              line-height: 0.24rem;
              text-align: center;
              border-radius: 100%;
              background-color: #fcc80e;
              font-size: 0.12rem;
            }

            .note_name {
              font-size: 18px;
              margin-left: 10px;
            }

            .present_scheme {
              color: $main_theme_color;
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          .curp,
          .curp-active {
            color: var(--textColor);
            font-size: 14px;

            .compare {
              margin-left: 24px;
            }
          }

          .curp-active {
            color: #fcc80e;
          }

          .create_time {
            font-size: 0.14rem;
            margin-left: 0.35rem;
            margin-top: 0.04rem;
          }

          .his_r_box {
            padding: 0.1rem 0 0.16rem 0.35rem;

            .his_r_box_bc {
              padding: 16px 16px 16px 16px;
              background-color: var(--darkColor);
              border-radius: 4px;
            }

            .his_r_box_bc_old {
              background: #f4f4f4;
            }

            .wrap_suggest {
              background-color: var(--backgroundColor);
              padding: 10px;
              border-radius: 4px;
            }

            .wrap_suggest_old {
              background: #f4f4f4;
            }

            .doctor_opinion {
              font-size: 16px;
              font-weight: 500;
              line-height: 0.22rem;
              margin-bottom: 0.04rem;
              opacity: 0.64;
            }

            .special_explain {
              font-size: 16px;
              font-weight: 500;
              word-break: break-all;
              line-height: 0.22rem;
            }

            .special_explain_old {
              color: $main_theme_color_333;
              background: #f4f4f4;
            }

            .suggest {
              font-size: 0.16rem;
              line-height: 22px;
              font-weight: 500;
              margin-bottom: 0.04rem;
              opacity: 0.64;
            }

            .honorific_doctor {
              font-size: 0.16rem;
              margin-bottom: 0.1rem;
              line-height: 0.22rem;
              word-wrap: break-word;
            }

            .honorific_doctor_old {
              background: #f4f4f4;
              color: $main_theme_color_333;
            }

            .his_r_p {
              color: #999999;
              font-size: 0.16rem;
              margin-bottom: 0.14rem;
            }

            .his_con {
              margin-bottom: 0.19rem;
              position: relative;

              .his_c_txt {
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 0.3rem;
                color: #999999;
                font-size: 0.16rem;
              }

              .his_c_sp {
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translate(110%, -50%);
                color: $main_theme_color;
                cursor: pointer;
              }
            }

            .his_r__yj {
              width: 100%;
              font-size: 0.14rem;
              display: flex;
              border-radius: 4px;
            }

            .his_r__yj_old {
              color: #333333;
              background: #f4f4f4;
            }

            .yj_left {
              min-width: 100%;
              line-height: 0.2rem;

              .yj_left_p {
                .operator_node {
                  flex: 1;
                  opacity: 0.64;
                }

                .operator_create_date_time {
                  opacity: 0.4;
                }
              }

              .yj_left_suggest {
                word-break: break-all;
                white-space: pre-wrap;
                text-indent: 0;
              }
            }

            .yj_right {
              flex: 1;

              .yj_single {
                width: 3rem;
                word-wrap: break-word;
                word-break: normal;
                line-height: 0.3rem;
                color: #999999;
                font-size: 0.16rem;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        &:last-child .his_r_box {
          border: none;
        }
      }
    }
  }

  .no_his {
    width: 100%;
    height: 100%;
    padding: 0.87rem 0 1.04rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $main_theme_color_333;

    .no_his_pic {
      width: 2.04rem;
      height: 1.28rem;
      margin-bottom: 0.27rem;
      background-size: 100% 100%;
    }

    .yellow_pic {
      background-image: url("../../../../../common/imgs/3d/pro_his_empty.png");
    }

    .no-data {
      font-size: 14px;
      color: var(--textColor);
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: opacity 0.2s;
}
.el-icon-close:hover{
  color: #fcc80e;
}
</style>
